<template>
  <section class="input_section">
    <div>
      <div class="taginput_name">
        <p class="input_name">
          {{ label_name ? label_name : '' }}
          <span v-if="filling_status">
            <img
              v-if="value.length"
              class="checkmark"
              src="@/assets/img/green-check.svg"
              alt="green-check"
            />
            <img
              v-else
              class="checkmark no_filled"
              src="@/assets/img/black-check.svg"
              alt="black-check"
            />
          </span>
        </p>
      </div>
      <div
        v-click-outside="closeData"
        class="fold_text_input"
      >
        <div class="add_input">
          <form @submit.prevent="addElementManual({ name: new_element_name })">
            <div :class="['data_wrapper', 'exist', { active_scroll: !!public_key?.comments_only }]">
              <div
                v-for="item in values_list"
                :id="`exists_elements_${item.id}`"
                :key="item.id"
                :class="['data_el', { 'disabled-block': !!public_key?.comments_only }]"
              >
                {{ item.name }}
                <img
                  v-if="use_close"
                  src="@/assets/img/close-icon.svg"
                  alt="close"
                  @click="deleteElementOrModal(item.id)"
                />
              </div>
              <input
                :id="`default_elements_${tagId}`"
                ref="input"
                v-model="new_element_name"
                :placeholder="placeholder"
                @input="spell_check_text = underlinedText($event.target.value)"
                @focus="show_fold_data = true"
              />
            </div>
          </form>
        </div>

        <div
          v-if="show_fold_data"
          class="fold"
        >
          <div
            v-if="filtered_available_elements.length"
            :class="['data_wrapper', 'exist', { active_scroll: !!public_key?.comments_only }]"
          >
            <div
              v-for="element in filtered_available_elements"
              :id="`default_elements_${element.id}`"
              :key="element.id"
              :class="['data_el', { 'disabled-block': !!public_key?.comments_only }]"
              @click="addElement(element)"
            >
              {{ element.name }}
            </div>
          </div>
          <div class="absent_el">
            <p>{{ not_set_values }}</p>
          </div>

          <!-- Этот блок показывается, если нет значений и нельзя добавлять руками-->
          <div
            v-if="!filtered_available_elements.length && no_manual"
            class="absent_el"
          >
            <img
              src="@/assets/img/fire-icon.svg"
              alt="fire-icon"
            />
            <p>{{ $t('forms.process.5.per.not_values_def') }}</p>
          </div>

          <div
            v-if="!no_manual"
            class="new_field_wrapper"
          >
            <div :class="['data_wrapper', { active_scroll: !!public_key?.comments_only }]">
              <span style="margin-right: 15px">
                {{ new_element_name.length ? $t('general.create') : $t('general.typing') }}</span
              >
              <div
                v-if="new_element_name.length"
                :class="['data_el', 'binded', 'yellow', { 'disabled-block': !!public_key?.comments_only }]"
                v-html="spell_check_text"
              ></div>
            </div>
            <div
              id="add_elem_btn"
              class="press_btn"
              @click="addElementManual({ name: new_element_name })"
            >
              <p>{{ $t('general.press') }}</p>
              <img
                src="@/assets/img/icon-keyboard-enter.svg"
                alt="keyboard-enter"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <delete-modal
      :show="show_delete_modal"
      :title="$t('general.modal.delete.common.title')"
      :desc="$t('general.modal.delete.common.desc')"
      @close="show_delete_modal = false"
      @approve="deleteElement(delete_index)"
    ></delete-modal>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import TagInputMixin from '@/components/global/inputs/mixins/TagInputMixin'
import DeleteModal from '@/components/global/modals/deleteModal.vue'
import { underlinedText } from '@/services/spell_check'

export default {
  name: 'TagInputObject',
  components: { DeleteModal },

  mixins: [TagInputMixin],

  props: {
    label_name: String,
    value: Array,
    available_elements: {
      type: Array,
      default: () => [],
    },
    compare_by: {
      type: String,
      default: 'id',
    },
    filling_status: Boolean,

    placeholder: String,
    new_element: {
      type: Object,
      default: () => {},
    },

    // before deleting ask you in modal window
    approve: {
      type: Boolean,
      default: false,
    },

    no_manual: {
      type: Boolean,
      default: false,
    },

    // * если не заданы значения, передаем текст
    not_set_values: String,

    tagId: {
      type: String,
      default: '',
    },

    use_close: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      show_fold_data: false,
      new_element_name: '',
      show_delete_modal: false,
      delete_index: null,
      spell_check_text: '',
    }
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapState('company', ['company', 'public_key']),

    values_list() {
      if (this.compare_by === 'name') {
        return this.available_elements.filter(el => this.value?.includes(el.name))
      }
      return this.available_elements.filter(el => this.value?.includes(el.id))
    },

    filtered_available_elements() {
      return this.available_elements.filter(
        el =>
          !this.values_list.includes(el) && el.name.toLowerCase().indexOf(this.new_element_name.toLowerCase()) !== -1,
      )
    },
  },

  methods: {
    underlinedText,
    addElement(element) {
      if (element.name.length) {
        this.$emit('add', { ...element, ...this.new_element })
        this.new_element_name = ''
      }
    },

    addElementManual(element) {
      if (!element.name.length) {
        return
      }
      if (this.values_list.map(i => i.name).includes(element.name)) {
        this.DANGER_TOAST(`${element.name} ${this.$t('general.errors.value_input_error')}`)
        return
      }
      if (this.available_elements.map(i => i.name).includes(element.name)) {
        this.addElement(element)
        return
      }
      this.$emit('addManual', { ...element, ...this.new_element })
      this.new_element_name = ''
    },

    deleteElementOrModal(index) {
      if (this.approve) {
        this.show_delete_modal = true
        this.delete_index = index
      } else {
        this.deleteElement(index)
      }
    },

    deleteElement(index) {
      this.$emit('delete', index)
      if (this.approve) {
        this.show_delete_modal = false
      }
    },
  },
}
</script>
