import router from '@/router'

export default {
  SELECT_PROCESS_MODAL(state, number) {
    state.process_modal = number
  },

  SELECT_SYSTEM_MODAL(state, number) {
    state.system_modal = number
  },

  SELECT_COM_MODAL(state, number) {
    state.com_modal = number
  },

  SELECT_INFO_MODAL(state, number) {
    state.info_modal = number
  },

  errorRequest(state, error) {
    if (error.response.status) {
      if (error.response.status === 404) {
        router.push('/404')
      } else if (error.response.status === 401) {
        router.push('/login/')
      } else if (error.response.status === 500) {
        router.push('/500')
      }
    }
  },

  // * =======================================================
  // * initial store functions

  loadCompanyFromApi(state, companyInfo) {
    state.get_company_loading = false
    state.company_info_from_api_client = companyInfo
  },

  ADD_SYSTEM_SCREENSHOT(state, file) {
    state.system.screenshots.push(file)
  },

  DANGER_TOAST(state, message) {
    state.toasts.unshift({
      message: message,
      status: 'error',
    })

    setTimeout(() => {
      state.toasts.pop()
    }, 5000)
  },

  WARNING_TOAST(state, message) {
    state.toasts.unshift({
      message: message,
      status: 'warning',
    })

    setTimeout(() => {
      state.toasts.pop()
    }, 5000)
  },

  SUCCESS_TOAST(state, message) {
    state.toasts.unshift({
      message: message,
      status: 'success',
    })

    setTimeout(() => {
      state.toasts.pop()
    }, 5000)
  },

  CHANGE_SIDEBAR(state) {
    state.is_sidebar = !state.is_sidebar
  },

  BANNER(state, data) {
    state.banner = {
      title: data.title,
      message: data.message,
      mail: data.mail,
    }
  },

  SET_SELECTED_TAGS(state, tags) {
    state.selected_tags = tags
  },
}
