import i18n from '@/locales'
import { api } from '@/main'
import _ from 'lodash'
import {
  AddSystemClient,
  checkApiResponse,
  FetchAllSystemDataClient,
  SearchSystemsClient,
  SystemClient, SystemClientInteractions,
  SystemOtherSourceClient,
  SystemSubjectClient,
  SystemThirdPartyClient,
  SystemThirdPartySubjectsClient,
} from '@/api/clients'

export default {
  async fetchSystems(context, payload) {
    const systemClient = new SystemClient(payload.company_id)
    const result = await systemClient.fetchAll(payload.limit, payload.offset)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEMS', result.data)
    }
  },

  async orderingSystems(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company_id}/systems/?offset=${payload.offset}&limit=${payload.limit}&ordering=${payload.ordering}`,
      context,
      {},
      'SET_SYSTEMS',
      // i18n.global.t('company.systems.loading_processes_error'),
    )
  },

  async fetchAllSystemData(context, payload) {
    const fetchAllData = new FetchAllSystemDataClient(payload.company, payload.system_id)
    const result = await fetchAllData.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('SET_INIT_SYSTEM_DATA', result.data)
    }
    await context.dispatch('company/fetchThirdParties', payload.company, { root: true })
  },

  async fetchSystem(context, payload) {
    // Получаем систему
    const systemClient = new SystemClient(payload.company)
    const result = await systemClient.fetch(payload.system_id)
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEM', result.data)
    }
  },

  async addSystem(context, payload) {
    // Добавляем систему
    const addSystemClient = new AddSystemClient(payload.company)
    const result = await addSystemClient.create(payload)
    // const result = await api.add({ url: `/api/v2/companies/${payload.company}/systems/`, data: payload })
    if (result.data?.id) {
      context.commit('ADD_SYSTEM', result.data)
      context.commit('SUCCESS_TOAST', i18n.global.t('general.success.system_create'), { root: true })
      await context.dispatch('company/fetchCompanyTags', payload.company, { root: true })
      return result
    }
    // Если не удается создать систему
    if (result.data?.freemium) {
      // Превышен лимит анкет для демо компании
      context.commit(
        'BANNER',
        {
          title: i18n.global.t('general.errors.quota.title'),
          message: i18n.global.t('general.errors.quota.message'),
          mail: i18n.global.t('general.errors.quota.mail'),
        },
        { root: true },
      )
    } else {
      context.commit('DANGER_TOAST', i18n.global.t('company.systems.creating_error'), { root: true })
    }
  },

  async deleteSystem(context, payload) {
    // Удаляет систему
    const systemClient = new SystemClient(payload.company)
    const result = await systemClient.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SYSTEM', payload.id)
    }
  },

  updateSystem: _.debounce(async (context, payload) => {
    // Обновляем поле системы
    let fields = {}
    fields[payload.field_name] = payload.value
    // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
    context.commit('UPDATE_SYSTEM', { id: payload.form.id, fields, getters: context.getters })
    // Теперь можно актуальные проценты передать вместе с данными на сервер
    const systemClient = new SystemClient(payload.form.company)
    const result = await systemClient.update(payload.form.id, { ...fields, percent: context.getters.percent })
    await checkApiResponse(result, context)
    if (!result) {
      // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
      await context.dispatch('fetchSystem', { company: payload.form.company, system_id: payload.form.id })
    }
  }, 500),

  async updateSystemFields(context, payload) {
    // Обновляем поля системы
    let fields = {}
    fields = payload.value
    // Сначала надо записать изменения в store, чтобы у нас были правильные проценты
    context.commit('UPDATE_SYSTEM', { id: payload.form.id, fields, getters: context.getters })
    // Теперь можно актуальные проценты передать вместе с данными на сервер
    const systemClient = new SystemClient(payload.form.company)
    const result = await systemClient.update(payload.form.id, { ...fields, percent: context.getters.percent })
    await checkApiResponse(result, context)
  },

  async deleteSystemDocument(context, payload) {
    // Обновляем поле анкеты
    let fields = {}
    fields[payload.field_name] = payload.new_uuids
    context.commit('UPDATE_SYSTEM', { id: payload.form.id, fields, getters: context.getters })
    const systemClient = new SystemClient(payload.form.company)
    const result = await systemClient.update(payload.form.id, { ...fields, percent: context.getters.percent })
    await checkApiResponse(result, context)
    if (!result.error) {
      //Удаляем документ, если смогли отвязать от системы
      await context.dispatch('company/deleteDocument', { uuid: payload.value }, { root: true })
    }
  },

  // Другие источники
  async fetchSourceOthers(context, payload) {
    // Получаем другие источники
    const fetchOtherSourceClient = new SystemOtherSourceClient(payload.company, payload.system)
    const result = await fetchOtherSourceClient.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEM_SOURCE_OTHERS', result.data)
    }
  },

  async addSourceOther(context, payload) {
    // Добавляем другой источник
    const addOtherSourceClient = new SystemOtherSourceClient(payload.company, payload.system)
    const result = await addOtherSourceClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SYSTEM_SOURCE_OTHER', result.data)
    }
    await context.dispatch('fetchSourceOthers', { ...payload, system: payload.system })
  },

  async deleteSourceOther(context, payload) {
    // Удаляет другой источник
    const { company, system } = payload
    const deleteOtherSourceClient = new SystemOtherSourceClient(company, system)
    const result = await deleteOtherSourceClient.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SYSTEM_SOURCE_OTHER', payload.id)
    }
    await context.dispatch('fetchSourceOthers', { ...payload, system_id: payload.system })
  },

  // Субъекты
  async fetchSystemSubjects(context, payload) {
    const fetchSubjectClient = new SystemSubjectClient(payload.company, payload.system_id)
    const result = await fetchSubjectClient.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEM_SUBJECTS', result.data)
    }
  },

  async addSystemSubject(context, payload) {
    // Добавляем субъект системы
    const addSubjectClient = new SystemSubjectClient(payload.company, payload.system)
    const result = await addSubjectClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SYSTEM_SUBJECT', result.data)
    }
  },

  async deleteSystemSubject(context, payload) {
    // Удаляет субъект системы
    const deleteSubjectClient = new SystemSubjectClient(payload.company, payload.system)
    const result = await deleteSubjectClient.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SYSTEM_SUBJECT', payload.id)
    }
  },

  async updateSystemSubject(context, payload) {
    let fields = {}
    fields[payload.field_name] = payload.value
    fields['id'] = payload.form.id
    const processSubjectClient = new SystemSubjectClient(payload.company, payload.form.system)
    const result = await processSubjectClient.update(payload.form.id, fields)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SYSTEM_SUBJECT', fields)
    }
    if (!result) {
      // Если сохранить не получилось, у нас не актуальные данные в store. Запрашиваем анкету заново
      await context.dispatch('getSystem', { company: payload.form.company, system_id: payload.form.id })
    }
  },

  // Третьи лица
  async fetchSystemThirdParties(context, payload) {
    const fetchSysThirdParties = new SystemThirdPartyClient(payload.company, payload.system)
    const result = await fetchSysThirdParties.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEM_THIRD_PARTIES', result.data)
    }
  },

  async addSystemThirdParty(context, payload) {
    const { company, system } = payload
    const addThirdParty = new SystemThirdPartyClient(company, system)
    const result = await addThirdParty.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SYSTEM_THIRD_PARTY', result.data)
    }
    await context.dispatch('company/fetchCompanyThirdPartiesDetails', payload.company, { root: true })
    return result
  },

  async deleteSystemThirdParty(context, payload) {
    const { company, system } = payload
    const deleteThirdParty = new SystemThirdPartyClient(company, system)
    const result = await deleteThirdParty.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SYSTEM_THIRD_PARTY', payload.id)
    }
  },

  updateSystemThirdParty: _.debounce(async (context, payload) => {
    // Обновляем систему
    let fields = {}
    fields[payload.field_name] = payload.value
    const updateProcessThParty = new SystemThirdPartyClient(payload.company, payload.system)
    const result = await updateProcessThParty.update(payload.form.id, fields)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SYSTEM_THIRD_PARTY', { ...fields, id: payload.form.id })
    }
  }, 1000),

  async deleteDocumentThirdParty(context, payload) {
    let fields = {}
    fields[payload.field_name] = payload.new_uuids
    context.commit('UPDATE_SYSTEM_THIRD_PARTY', { ...fields, id: payload.form.id })

    const result = await api.query(
      'UPDATE',
      `/companies/${payload.company}/systems/${payload.system}/system_third_parties/${payload.form.id}/`,
      context,
      fields,
      null,
      '',
    )
    if (!result.error) {
      //Удаляем документ, если смогли отвязать
      await context.dispatch('company/deleteDocument', { uuid: payload.value }, { root: true })
    }
  },

  async addSystemThirdPartySubject(context, payload) {
    const addThirdPartySubject = new SystemThirdPartySubjectsClient(payload.system.company, payload.system)
    const result = await addThirdPartySubject.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SYSTEM_THIRD_PARTY_SUBJECT', result.data)
    }
  },

  async deleteSystemThirdPartySubject(context, payload) {
    const delSystemThirdPartySubj = new SystemThirdPartySubjectsClient(payload.system.company, payload.system)
    const result = await delSystemThirdPartySubj.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SYSTEM_THIRD_PARTY_SUBJECT', payload.id)
    }
  },

  updateSystemThirdPartySubject: _.debounce(async (context, payload) => {
    // Обновляем третьи лица в системе
    let fields = {}
    fields[payload.field_name] = payload.value
    const updateThirdPartySubject = new SystemThirdPartySubjectsClient(payload.system.company, payload.system)
    const result = await updateThirdPartySubject.update(payload.form.id, fields)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SYSTEM_THIRD_PARTY_SUBJECT', result.data)
    }
  }, 100),

  async searchSystems(context, payload) {
    const searchSystem = new SearchSystemsClient(payload.company_id, payload.search)
    const result = await searchSystem.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('FILTER_SYSTEMS', result.data)
    }
  },

  async filteredTagsSystems(context, payload) {
    await api.query(
      'GET',
      `/companies/${payload.company_id}/systems/?tags=${payload.tags}&limit=${payload.limit}`,
      context,
      {},
      'SET_SYSTEMS',
      i18n.global.t('company.systems.loading_systems_error'),
    )
  },
  // Взаимодействие с другими информационными системами
  async fetchSystemInteractions(context, payload) {
    const fetchSystemInteraction = new SystemClientInteractions(payload.company, payload.system)
    const result = await fetchSystemInteraction.fetchAll()
    if (await checkApiResponse(result, context)) {
      context.commit('SET_SYSTEM_INTERACTIONS', result.data)
    }
  },

  async addSystemInteraction(context, payload) {
    const addInteractionsClient = new SystemClientInteractions(payload.company, payload.system)
    const result = await addInteractionsClient.create(payload)
    if (await checkApiResponse(result, context)) {
      context.commit('ADD_SYSTEM_INTERACTION', result.data)
    }
  },

  async deleteSystemInteraction(context, payload) {
    const delInteractionsClient = new SystemClientInteractions(payload.company, payload.system)
    const result = await delInteractionsClient.delete(payload.id)
    if (await checkApiResponse(result, context)) {
      context.commit('DELETE_SYSTEM_INTERACTION', payload.id)
    }
  },

  updateSystemInteraction: _.debounce(async (context, payload) => {
    // Обновляем данные
    let fields = {}
    fields[payload.field_name] = payload.value
    const updateThirdPartySubject = new SystemClientInteractions(payload.company, payload.system)
    const result = await updateThirdPartySubject.update(payload.id, fields)
    if (await checkApiResponse(result, context)) {
      context.commit('UPDATE_SYSTEM_INTERACTION', { ...fields, id: payload.id })
    }
    await context.dispatch('fetchSystemInteractions', payload)
  }, 50),
}
