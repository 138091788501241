import { api } from '@/main'
import i18n from '@/locales'
import router from '@/router'
import _ from 'lodash'

export default {
  async getMe() {
    try {
      const response = await api.client(`${api.api_url}/users/me/`)
      if (response.status !== 200 || !response.data.username) {
        return false
      }
      return response.data
    } catch {
      return false
    }
  },

  async check_auth(context, payload = {}) {
    // Если есть токен публичной ссылки в урл, надо его сохранить в сервисе
    // и удалить данные о старом пользователе
    const params = payload.route.params
    if ('token' in params) {
      // Todo: Можно добавить проверку, что для этого токена на беке есть активный ключ
      api.userService.privacyHeader = `PrivacyToken ${params.token}`
      clear_local_storage_items(['accessToken', 'refreshToken'])
      context.commit('CLEAR_ME')
      context.commit('UNSET_NEED_LOGIN')
      context.commit('SET_PUBLIC_MODE', true)
      return true
    }

    // Если сервер отдает нам информацию о пользователе, мы авторизованы
    const me = await context.dispatch('getMe')
    if (me.password_expired) {
      context.commit('DANGER_TOAST', i18n.global.t('user.need_new_password'), { root: true })
    } else if (me) {
      context.commit('SET_ME', me)
      api.userService.privacyHeader = null
      context.commit('UNSET_NEED_LOGIN')
      context.commit('SET_PUBLIC_MODE', false)
      if (me.need_new_password) {
        const payload = {
          message: i18n.global.t('user.need_new_password'),
          link: i18n.global.t('user.change_password'),
        }
        context.commit('SET_INFO_MESSAGE', payload)
      }
      return true
    }
    // Если пользователь не авторизован, чистим все и отправляем на страницу авторизации
    api.userService.privacyHeader = null
    clear_local_storage_items(['accessToken', 'refreshToken'])
    context.commit('CLEAR_ME')
    context.commit('SET_PUBLIC_MODE', false)
    context.commit('SET_NEED_LOGIN')
    if (payload.route.name !== 'login') {
      await router.push({ name: 'login' })
    }
    return false
  },

  async login(context, payload) {
    // Отправляем запрос на авторизацию пользователя
    try {
      const response = await api.client.post(`${api.server}/api-auth/token/`, payload)
      if (response.status !== 200) {
        return false
      }
      localStorage.setItem('accessToken', response.data.access)
      localStorage.setItem('refreshToken', response.data.refresh)
      return true
    } catch (error) {
      context.commit('DANGER_TOAST', error.response.data?.detail, { root: true })
      return false
    }
  },

  async logout(context) {
    context.commit('CLEAR_ME')
    await api.client.post(`${api.server}/api-auth/token/logout/`, {
      refresh_token: localStorage.getItem('refreshToken'),
    })
    clear_local_storage_items(['accessToken', 'refreshToken', 'company', 'theme', 'lastActivity'])
    context.commit('company/CLEAR_LOADED', null, { root: true })
    context.commit('SET_NEED_LOGIN')
    await router.push({ name: 'login' })
  },

  async login_by_keycloak(context, payload) {
    // Отправляем запрос на авторизацию пользователя
    try {
      const response = await api.client.post(`${api.server}/api-auth/token/keycloak/`, payload)
      if (response.status !== 200) {
        return false
      }
      localStorage.setItem('accessToken', response.data.access)
      localStorage.setItem('refreshToken', response.data.refresh)
      return true
    } catch (error) {
      context.commit('DANGER_TOAST', error.response.data?.detail, { root: true })
      return false
    }
  },

  async fetchAllUsers({ commit }) {
    const result = await api.fetch({ url: '/api/v2/users/' })
    commit('SET_USERS', result.data)
  },

  updateUser: _.debounce(async (context, payload) => {
    let data = {}
    data[payload.field_name] = payload.value
    await api.query('UPDATE', `/users/${payload.user_id}/`, context, data, 'UPDATE_USER')
  }, 500),
}

function clear_local_storage_items(items) {
  // remove some items in localStorage
  items.forEach(el => localStorage.removeItem(el))
}
