//Сервис для проверки орфографических ошибок в слове
import Typo from 'typo-js'
import affData from '@/dictionaries/ru_RU.aff'
import dicData from '@/dictionaries/ru_RU.dic'
const ruDictionary = new Typo('ru_RU', affData, dicData, { platform: 'any' })

//Возвращаем true если слово не имеет орфографических ошибок, либо содержит числа
export const spellCheckWords = value => {
  //Проверяем есть ли в конце текста число, латинская буква, кавычки
  const end_number = /[a-zA-Z\d"']$/.test(value)
  if (!value || end_number) return true
  //Удаляем знаки препинания из текста для проверки каждого слова
  const no_punctuation = value.trim().replace(/[\p{P}\p{S}]/gu, '')
  const words = no_punctuation.split(/\s+/)
  //Ищем последнее слово
  const last_word = words[words.length - 1]
  //Проверяем последнее слово в словаре typo-js если оно не содержит пробела
  return !last_word.endsWith(' ') ? !!ruDictionary.check(last_word) : true
}

export const underlinedText = value => {
  const words = value.split(/\s+/)
  // Оборачиваем слова с ошибками в span, чтобы показать орфографические ошибки
  return words
    .map(word => {
      // Проверяем правильность слова
      if (!spellCheckWords(word)) {
        // Если ошибка в слове оборачиваем в span
        return `<span class="underline_error" style="color:black">&nbsp${word}</span>&nbsp`
      }
      //Выводим слова без ошибок
      return word
    })
    .join('  ')
}
